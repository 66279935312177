<template>
  <v-row
    class="px-2 mt-2"
    align="center"
  >
    <v-col
      cols="12"
      md="3"
      class="py-4"
    >
      <v-autocomplete
        v-model="subs_code"
        :items="subsCodes"
        placeholder="定期便コード"
        outlined
        clearable
        dense
        hide-details
      ></v-autocomplete>
    </v-col>
    <v-spacer></v-spacer>
    <v-col
      cols="12"
      md="3"
      style="text-align:right"
    >
      <v-btn
        color="secondary"
        outlined
        :loading="exportStatus"
        :disabled="selItemCodes.length === 0"
        @click="downloadCSV"
      >
        <v-icon
          size="17"
          class="me-1"
        >
          {{ icon.mdiExportVariant }}
        </v-icon>
        <span>Export</span>
      </v-btn>
    </v-col>
    <v-col
      cols="12"
      class="pa-0"
    >
      <v-data-table
        ref="MthlySubsStock"
        v-model="selectedDatas"
        :headers="headers"
        :items="SubsMonthlyStock"
        item-key="item_code"
        :items-per-page="-1"
        show-select
      >
        <template v-slot:[`item.subs_code`]="{ item }">
          <a
            href="javascript:void(0);"
            @click="pushSubsDaily(item)"
          >{{ item.subs_code }}</a>
        </template>

        <template v-slot:[`item.out_qty`]="{ item }">
          <label style="color:#F44336">{{ item.out_qty? item.out_qty.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,') : '0' }}</label>
        </template>
        <template v-slot:[`item.quantity_sum`]="{ item }">
          <label style="color:#F44336">{{ item.out_qty? item.out_qty.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,') : '0' }}</label>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import {
  mdiPencil, mdiDelete, mdiExportVariant,
} from '@mdi/js'
import {
  mapState, mapMutations, mapActions,
} from 'vuex'

// import agent from '@/api/agent'

export default {
  data: () => ({
    dialogDelete: false,
    icon: {
      mdiPencil, mdiDelete, mdiExportVariant,
    },
    subs_code: '',
    subsCodes: [],
    selectedDatas: [],
    selItemCodes: [],
    exportStatus: false,
    headers: [
      {
        text: '定期便コード',
        align: 'left',
        value: 'subs_code',
        width: '15%',
        fixed: true,
      },
      {
        text: '定期便名',
        align: 'left',
        value: 'subs_name',
        width: '50%',
        fixed: true,
      },

      {
        text: '出荷数量',
        value: 'out_qty',
        align: 'right',
        width: '15%',
        fixed: true,
      },
      {
        text: '合計',
        value: 'quantity_sum',
        align: 'right',
        width: '17%',
        fixed: true,
      },

    ],
  }),

  computed: {
    ...mapState('monthlyStockStore', ['SubsMonthlyStock']),

    // ...mapState('subscriptionStore', ['subsCodes']),
  },

  watch: {
    subs_code(newValue) {
      this.loadData(this.$route.params.month, newValue)
    },
    selectedDatas(newValue) {
      this.selItemCodes = newValue.map(n => n.item_code)
    },
  },

  mounted() {
    this.$emit('has-mounted')
  },

  methods: {
    ...mapActions('monthlyStockStore', ['loadSubsMonthlyStock']),

    // ...mapActions('subscriptionStore', ['loadSubscriptionCodes']),
    ...mapMutations('app', ['setOverlayStatus']),
    pushSubsDaily(item) {
      this.$router.push({
        name: 'stock-subs-daily',
        params: {
          month: this.$route.params.month,
        },
        query: {
          selTab: this.$route.query.selTab,
          product_code: item.subs_code,
        },

        // query: { originalMth: this.$route.params.month, selTab: 'product' },
      })
    },
    loadData(selMonth, selSubsCode) {
      this.setOverlayStatus(true)

      // this.loadSubscriptionCodes(),
      this.loadSubsMonthlyStock({
        type: 'subscription', month: selMonth, subs_code: selSubsCode,
      }).then(() => {
        this.$nextTick(() => {
          this.$refs.MthlySubsStock.toggleSelectAll(true)
        })
        this.subsCodes = this.SubsMonthlyStock.map(sms => sms.subs_code)
      }).catch(error => {
        console.log('loadData', error)
      }).finally(() => {
        this.setOverlayStatus(false)
      })
    },
    downloadCSV() {
      // this.exportStatus = true
      // agent.Report.monthlyReportDownload({
      //   date: this.$route.params.month,
      //   data: {
      //     type: 'product',
      //     target_ids: this.selItemCodes,
      //   },
      // }).then(() => {
      //   this.exportStatus = false
      // })
    },
  },
}
</script>
<style scoped>
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
</style>

<template>
  <div id="Monthly-stock-list">
    <v-card>
      <v-card-title>月次入出庫</v-card-title>
      <v-tabs v-model="Stocktabs">
        <v-tab
          href="#item"
          @click="loadItemStockMonthly"
        >
          単品
        </v-tab>
        <v-tab
          href="#set"
          @click="loadSetStockMonthly"
        >
          セット
        </v-tab>
        <v-tab
          href="#subscription"
          @click="loadSubsStockMonthly"
        >
          定期便
        </v-tab>
        <v-spacer></v-spacer>
        <v-col
          cols="1"
          @click="reduceMonth"
        >
          <v-icon class="me-1">
            {{ icons.mdiChevronLeft }}
          </v-icon>
        </v-col>
        <v-col cols="2">
          <label>{{ selectedMonth.substring(0, 4) }}年{{ selectedMonth.substring(4,6) }}月</label>
        </v-col>
        <v-col
          v-if="selectedMonth < currentMonth"
          cols="1"
        >
          <v-icon
            class="me-1"
            @click="addMonth"
          >
            {{ icons.mdiChevronRight }}
          </v-icon>
        </v-col>
        <v-col
          v-else
          cols="1"
        >
        </v-col>
      </v-tabs>
      <v-tabs-items
        v-model="Stocktabs"
        touchless
      >
        <v-tab-item value="item">
          <item-stock-monthly
            ref="itemStockMonthly"
            @has-mounted="getItemStock"
          ></item-stock-monthly>
        </v-tab-item>
        <v-tab-item value="set">
          <set-stock-monthly
            ref="setStockMonthly"
            @has-mounted="getSetStock"
          ></set-stock-monthly>
        </v-tab-item>
        <v-tab-item value="subscription">
          <subs-stock-monthly
            ref="subsStockMonthly"
            @has-mounted="getSubsStock"
          ></subs-stock-monthly>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import {
  mdiChevronLeft,
  mdiChevronRight,
} from '@mdi/js'
import {
  mapMutations,
} from 'vuex'
import ItemStockMonthly from './ItemStockMonthly.vue'
import SetStockMonthly from './SetStockMonthly.vue'
import SubsStockMonthly from './SubsStockMonthly.vue'

export default {
  components: {
    ItemStockMonthly,
    SetStockMonthly,
    SubsStockMonthly,
  },
  data: () => ({
    Stocktabs: 'product',
    ItemSotckMthlyFlag: false,
    SetSotckMthlyFlag: false,
    SubsSotckMthlyFlag: false,
    icons: {
      mdiChevronLeft,
      mdiChevronRight,
    },
    currentMonth: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substring(0, 7).replace('-', ''),
  }),

  computed: {
    selectedMonth() {
      return this.$route.params.month
    },
  },

  watch: {
    Stocktabs(newValue) {
      this.$router.push({
        query: {
          selTab: newValue,
        },
      })
    },
  },

  mounted() {
    if (this.$route.query.selTab) {
      this.Stocktabs = this.$route.query.selTab
    }
    this.loadSelTab()
  },

  methods: {
    ...mapMutations('monthlyStockStore', ['clearItemMonthlyStock']),
    ...mapMutations('app', ['setOverlayStatus']),
    sleep(t) {
      const a = new Promise(resolve => {
        setTimeout(() => { resolve() }, t)
      })

      return a
    },

    clearCache() {
      this.clearItemMonthlyStock()
    },
    loadSelTab() {
      if (this.Stocktabs === 'item') {
        this.loadItemStockMonthly()
      }
      if (this.Stocktabs === 'set') {
        this.loadSetStockMonthly()
      }
      if (this.Stocktabs === 'subscription') {
        this.loadSubsStockMonthly()
      }
    },
    getItemStock() {
      this.ItemSotckMthlyFlag = true
    },
    getSetStock() {
      this.SetSotckMthlyFlag = true
    },
    getSubsStock() {
      this.SubsSotckMthlyFlag = true
    },
    async loadItemStockMonthly() {
      this.clearCache()
      this.setOverlayStatus(true)
      /* eslint-disable-next-line */
      while (!this.ItemSotckMthlyFlag) { await this.sleep(1000) }
      console.log('item clicked:', this.selectedMonth)
      this.$refs.itemStockMonthly.loadData(this.selectedMonth)
    },
    async loadSetStockMonthly() {
      this.clearCache()
      this.setOverlayStatus(true)
      /* eslint-disable-next-line */
      while (!this.SetSotckMthlyFlag) { await this.sleep(1000) }
      console.log('loadSetStockMonthly:', this.selectedMonth)
      this.$refs.setStockMonthly.loadData(this.selectedMonth)
    },

    async loadSubsStockMonthly() {
      this.clearCache()
      this.setOverlayStatus(true)
      /* eslint-disable-next-line */
      while (!this.SubsSotckMthlyFlag) { await this.sleep(1000) }
      console.log('loadSubsStockMonthly:', this.selectedMonth)
      this.$refs.subsStockMonthly.loadData(this.selectedMonth)
    },
    reduceMonth() {
      const date = new Date(`${this.selectedMonth.substring(0, 4)}-${this.selectedMonth.substring(4, 6)}`)
      const selMonth = new Date(date.setMonth(date.getMonth() - 1)).toISOString().substring(0, 7).replace('-', '')
      this.$router.push({
        name: 'stock-monthly',
        params: {
          month: selMonth,
        },
        query: {
          selTab: this.$route.query.selTab,
        },
      })
      this.loadSelTab()
    },
    addMonth() {
      const date = new Date(`${this.selectedMonth.substring(0, 4)}-${this.selectedMonth.substring(4, 6)}`)
      const selMonth = new Date(date.setMonth(date.getMonth() + 1)).toISOString().substring(0, 7).replace('-', '')
      console.log(selMonth, this.currentMonth)
      if (selMonth <= this.currentMonth) {
        this.$router.push({
          name: 'stock-monthly',
          params: {
            month: selMonth,
          },
          query: {
            selTab: this.$route.query.selTab,
          },
        })
      }
      this.loadSelTab()
    },
  },
}
</script>

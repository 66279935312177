<template>
  <v-row
    class="px-2 mt-2"
    align="center"
  >
    <v-col
      cols="12"
      md="3"
      class="py-4"
    >
      <v-autocomplete
        v-model="item_code"
        :items="itemCodes"
        placeholder="単品コード"
        outlined
        clearable
        dense
        hide-details
      ></v-autocomplete>
    </v-col>
    <v-spacer></v-spacer>
    <v-col
      cols="12"
      md="3"
      style="text-align:right"
    >
      <v-btn
        color="secondary"
        outlined
        :loading="exportStatus"
        :disabled="selItemCodes.length === 0"
        @click="downloadCSV"
      >
        <v-icon
          size="17"
          class="me-1"
        >
          {{ icon.mdiExportVariant }}
        </v-icon>
        <span>Export</span>
      </v-btn>
    </v-col>
    <v-col
      cols="12"
      class="pa-0"
    >
      <v-data-table
        ref="MthlyItemStock"
        v-model="selectedDatas"
        :headers="headers"
        :items="f_ItemMonthlyStock"
        item-key="item_code"
        :items-per-page="-1"
        show-select
      >
        <template v-slot:[`item.item_code`]="{ item }">
          <a
            href="javascript:void(0);"
            @click="pushItemDaily(item)"
          >{{ item.item_code }}</a>
        </template>
        <template v-slot:[`item.quantity_in`]="{ item }">
          <label>{{ item.quantity_in ? item.quantity_in.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,') : '0' }}</label>
        </template>
        <template v-slot:[`item.quantity_out`]="{ item }">
          <label style="color:#F44336">{{ item.quantity_out ? item.quantity_out.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,') : '0' }}</label>
        </template>
        <template v-slot:[`item.quantity_sum`]="{ item }">
          <label :style="item.quantity_sum < 0 ? 'color:#F44336' : ''">{{ item.quantity_sum ? item.quantity_sum.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,') : '0' }}</label>
        </template>
        <template v-slot:[`item.monco_qty`]="{ item }">
          <label :style="item.monco_qty < 0 ? 'color:#F44336' : ''">{{ item.monco_qty ? item.monco_qty.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,') : '0' }}</label>
        </template>
        <template v-slot:[`item.sum_total`]="{ item }">
          <label :style="item.sum_total < 0 ? 'color:#F44336' : ''">{{ item.sum_total ? item.sum_total.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,') : '0' }}</label>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import {
  mdiPencil, mdiDelete, mdiExportVariant,
} from '@mdi/js'
import {
  mapState, mapMutations, mapActions,
} from 'vuex'

// import agent from '@/api/agent'

export default {
  data: () => ({
    dialogDelete: false,
    icon: {
      mdiPencil, mdiDelete, mdiExportVariant,
    },
    itemCodes: [],
    item_code: '',
    selectedDatas: [],
    selItemCodes: [],
    exportStatus: false,
    headers: [
      {
        text: '単品コード',
        align: 'left',
        value: 'item_code',
        width: '15%',
        fixed: true,
      },
      {
        text: '単品名',
        align: 'left',
        value: 'item_name',
        width: '25%',
        fixed: true,
      },
      {
        text: '入荷数量',
        value: 'quantity_in',
        align: 'right',
        width: '10%',
        fixed: true,
      },
      {
        text: '出荷数量',
        value: 'quantity_out',
        align: 'right',
        width: '10%',
        fixed: true,
      },
      {
        text: '合計',
        value: 'quantity_sum',
        align: 'right',
        width: '10%',
        fixed: true,
      },
      {
        text: '前月繰越数量',
        value: 'monco_qty',
        align: 'right',
        width: '17%',
        fixed: true,
      },
      {
        text: '累計在庫数量',
        value: 'sum_total',
        align: 'right',
        width: '17%',
        fixed: true,
      },
    ],
  }),

  computed: {
    ...mapState('monthlyStockStore', ['ItemMonthlyStock', 'f_ItemMonthlyStock']),
  },

  watch: {
    item_code() {
      this.filterItemMonthlyStock(this.item_code)
    },
    selectedDatas(newValue) {
      this.selItemCodes = newValue.map(n => n.item_code)
    },
  },

  mounted() {
    this.$emit('has-mounted')
  },

  methods: {
    ...mapActions('monthlyStockStore', ['loadItemMonthlyStock']),
    ...mapMutations('monthlyStockStore', ['filterItemMonthlyStock']),
    ...mapMutations('app', ['setOverlayStatus']),
    pushItemDaily(item) {
      console.log('pushItem:', this.$route.params.month, item.item_code)
      this.$router.push({
        name: 'stock-item-daily',
        params: {
          month: this.$route.params.month,
        },
        query: {
          selTab: this.$route.query.selTab,
          item_code: item.item_code,
        },

        // query: { originalMth: this.$route.params.month, selTab: 'product' },
      })
    },
    loadData(selMonth) {
      this.loadItemMonthlyStock(selMonth).then(() => {
        this.filterItemMonthlyStock(this.item_code)
        this.itemCodes = this.ItemMonthlyStock.map(fs => fs.item_code)
        this.$nextTick(() => {
          this.$refs.MthlyItemStock.toggleSelectAll(true)
        })
        this.setOverlayStatus(false)
      })
    },
    downloadCSV() {
      // this.exportStatus = true
      // agent.Report.monthlyReportDownload({
      //   date: this.$route.params.month,
      //   data: {
      //     type: 'product',
      //     target_ids: this.selItemCodes,
      //   },
      // }).then(() => {
      //   this.exportStatus = false
      // })
    },
  },
}
</script>
<style scoped>
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
</style>
